<template>

<div class="account-rate table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">收款微信列表</span>
        <el-form inline   class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addList">扫码添加</el-button>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="resetList">刷新列表</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="头像" prop="headImgUrl">
                <template slot-scope="scope">
                    <div>
                        <el-image
                            :src="scope.row.headImgUrl"
                            style="width:50px"
                        />
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="昵称" prop="nickname" show-overflow-tooltip></el-table-column>
            <el-table-column label="openId" prop="openId" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作">
                <template  slot-scope="scope">
                    <el-button class="themed-button" type="danger" size="mini" @click="delect(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>

    <el-dialog
            title="扫码绑定"
            :visible.sync="wechartURLDialogVisible"
            @close="closeCode"
            width="400px"
            center>
            <div style="width:150px;height:150px;margin:auto">
                <div id="qrcode" ref="qrcode" style="text-align:center"></div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="sureScan">已绑定</el-button>
                <el-button @click="wechartURLDialogVisible = false">关闭</el-button>
            </span>
        </el-dialog>
</div>

</template>

<script type="text/ecmascript-6">
import QRCode from "qrcodejs2"
import {bindWxList,delWxExtra,getWxExtraUrl} from '@/api/welcome.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import resize from '@/mixins/resize.js'
export default {
    mixins:[resize],
    data(){
        return {
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            wechartURLDialogVisible:false,
            wechartUrl:'',
        }
    },
    mounted(){
       this.getList()
    },
    methods:{
        getList(){
            let params = {
				pageSize: this.pageSize,
				pageNo: this.page,
			}
            bindWxList(params).then(res=>{
                this.total = res.total
				this.tableData = res.rows
            })
        },

        resetList(){
            this.getList()
        },

        addList(){
			getWxExtraUrl({addExtra:1}).then(res=>{
					this.wechartURLDialogVisible = true
					this.wechartUrl = res.url
					this.$nextTick(() => {
						this.crateQrcode()
					})
			})
        },

        delect(id){
            this.$confirm('确定要删除吗？', '消息提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delWxExtra({id}).then(res=>{
                        this.$message({
							type: 'success',
							message: '删除成功!'
						})
						this.getList()
                    })
				})
        },

        crateQrcode () {
				let qr = new QRCode('qrcode', {
				width: 150,
				height: 150, // 高度
				text: this.wechartUrl // 二维码内容
            	})
        },
        
        sureScan(){
            this.wechartURLDialogVisible = false
            this.page = 1
            this.getList()
        },

        closeCode(){
				this.wechartURLDialogVisible = false
				this.$refs.qrcode.innerHTML = ''
        },
        
        handleSizeChange(size) {
			this.pageSize = size
			this.getList()
        },

		onPageChange(page) {
			this.page = page
			this.getList()
		},
    }
}
</script>
